var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-breadcrumb',{attrs:{"pageTitle":_vm.$t('models.licence'),"breadcrumb":[
          {
            text: _vm.$t('Licences'),
            to: 'licences'
          },
          {
            text: _vm.$t('Detail'),
            active: true,
          } ]}}),(_vm.userData)?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('licence-view-info-card',{attrs:{"user-data":_vm.userData},on:{"refetch-data":function($event){return _vm.getData(_vm.userData.id)}}}),(_vm.$can('manage', 'all'))?_c('b-card-actions',{ref:"refreshCard",attrs:{"title":"SSO","action-refresh":""},on:{"refresh":function($event){return _vm.refreshSSO('refreshCard')}}},[_c('b-card-sub-title',[_vm._v(_vm._s(_vm.userData.sso))])],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('licence-view-permissions-card',{attrs:{"user-data":_vm.userData,"permissionData":_vm.permissionData}})],1)],1)]:_c('b-alert',{attrs:{"variant":"danger","show":_vm.userData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error fetching user data ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" No user found with this user id. Check "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'licences'}}},[_vm._v(" Licence List ")]),_vm._v(" for other users. ")],1)]),(_vm.userData)?_c('licence-view-payments-card',{attrs:{"payments":_vm.userData.payments}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }